
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "lokasi";
const modelPath = `/perawatan/${modelName}/`;

class Lokasi extends ModelBase {
  constructor() {
    let model = {
      id: null,
      nama: ""
    };
    let requiredFields = ["nama"];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default Lokasi;
