<template>
  <div class="card">
    <header class="card-header has-background-primary">
      <p class="card-header-title has-text-light is-uppercase">TAMBAH LOKASI</p>
    </header>
    <div class="card-content events-card">
      <b-field class="field-spacer"></b-field>
      <div class="notification is-info is-light">
        Pastikan data yang akan diinputkan belum ada di database aplikasi. Cek
        terlebih dahulu dengan cara kembali ke halaman sebelumnya dan mengisi
        (minimal) tiga huruf untuk menampilkan data yang sudah ada di sistem.
      </div>
      <b-field class="field-spacer"></b-field>
      
      <template v-if="errorMap.non_field_errors">
        <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
        <b-field class="field-spacer"></b-field>
      </template>

      <b-field
        label="Lokasi"
        custom-class="is-small"
        :type="{'is-danger': errorMap.nama}"
        :message="errorMap.nama"
      >
        <b-input v-model="lokasi.nama" required @input="validateInput('nama')"></b-input>
      </b-field>

      <b-field class="field-spacer"></b-field>
      <div class="buttons">
        <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
        <b-button
          class="is-primary has-text-weight-semibold"
          :disabled="!isValid"
          @click.stop.prevent="saveObject"
        >Simpan</b-button>
      </div>
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import { has } from "lodash";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Lokasi from "../models/lokasi.js";

export default {
  name: "LokasiCreate",
  mixins: [createUpdateMixin],
  // components: {
  //   GenericSelect: () => import("@/apps/core/components/GenericSelect.vue")
  // },
  data() {
    // created dipanggil lebih dulu dari created()
    this.lokasiMdl = new Lokasi();
    this.objectMdl = this.lokasiMdl; // alias yg digunakan di cuMixin
    return this.lokasiMdl.getObservables();
  },
  methods: {
    ...mapActions("aktivitas", ["setAktPSPDState", "setKegiatanLokasi"]),
    validateInput(field) {
      this.lokasiMdl.validate(field);
    },
    onSaved(respData) {
      if (has(this.$route.params, "aktivitasLokasi")) {
        this.$route.params.aktivitasLokasi["lokasi"] = respData.id;
        this.$route.params.aktivitasLokasi["edited"] = true;
      } else if (has(this.$route.params, "kegiatanLokasi")) {
        this.$route.params.kegiatanLokasi["lokasi"] = respData.id;
        this.$route.params.kegiatanLokasi["edited"] = true;
      }
      this.cancel();
    },
    cancel() {
      if (has(this.$route.params, "aktivitasLokasi")) {
        let aktivitasLokasi = this.$route.params.aktivitasLokasi;
        this.setAktPSPDState(aktivitasLokasi);
      } else if (has(this.$route.params, "kegiatanLokasi")) {
        let kegiatan = this.$route.params.kegiatanLokasi;
        this.setKegiatanLokasi(kegiatan);
      }
      this.$router.go(-1);
    }
  },
  watch: {
    lokasi: {
      // perawatan berubah, update edited
      handler(newValue, oldValue) {
        if (!this.lokasiMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>
